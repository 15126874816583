<template>
  <div class="right ">
    <div class="card">
      <div>
        <div class="flex">
          <span class="label is-bold">Settle Payment</span>
        </div>
        <v-button
          class="button-theme button-theme-padding-13"
          @click="hanlderCancel"
        >
          <span>Cancel</span>
        </v-button>
        <v-button
          class="button-blue button-theme-padding-13"
          @click="handlerUpdate"
        >
          <span>Update</span>
        </v-button>

      </div>

    </div>
  </div>

</template>

<script>
import {mapActions, mapState} from 'vuex'

export default {
  name: 'settlePaymentDailog',
  props:{
  },
  methods:{
    hanlderCancel(){
      this.$router.back()
    },
    handlerUpdate(){
      this.$emit('next')
    }

  }
}
</script>

<style scoped lang="scss">
.right {
  //margin-top: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  .card {
    background: white;
    border-radius: 5px;
    padding: 15px;
  }
  .button-blue{
    width: 100%;
    margin-bottom: 20px;
    margin-left: 0;
  }
  .button-theme{
    width: 100%;
    margin-bottom: 20px;
  }
  .is-bold{
    font-weight: bold;
    font-size: 24px;
    color: #000000;
    margin-bottom: 20px;
  }
}
.margin-right{
  margin-right: 5px;
}
</style>