<template>
  <section
    ref="page"
    class="page-container">



    <el-form
      ref="form"
      :model="formData"
    >
      <el-row
        :gutter="40"
        justify="space-around">
        <el-col
          :xs="24"
          :sm="24"
          :md="12"
          :lg="17"
          :xl="17"
          class="page-content-and-up"
        >
          <div class="flex-justify flex">
            <div
              class="header-title flex"
            >

              <span>
                Update Payment
              </span>
            </div>
          </div>
          <div class="flex-justify flex">
            <div
              class=" flex paid-item"
            >
              <span>
                Paid on account
              </span>
              <span class="pay">
                {{payment.amount_received|formatMoney(2,true)}}
              </span>
            </div>
          </div>
          <select-payment
            ref="set-payment"
            :form="formData"
            :isUpdatePayment="true"
            :formDataLock="formDataLock"
            @getFormData="getSubFormData"
            @handlerApprove="handlerApprove"
          ></select-payment>
        </el-col>
        <el-col
          class="padding-none-sm-and-down  page-content-auto"
          :xs="24"
          :sm="24"
          :md="24"
          :lg="5"
          :xl="5">
          <div class="page-content-fixed">
            <settle-payment-dailog
              @next="handlerValidate"
            ></settle-payment-dailog>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </section>
</template>

<script>
import selectPayment from '@/views/components/selectPayment/selectPayment'
import {mapActions, mapState} from 'vuex'
import {getOrderDetail, setOrderPayment, orderDownQuotation, approvePayment,setOrderConfirm} from '@api/order'
import MixinOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import SettlePaymentDailog from '@/views/components/dialog/settlePaymentDialog'
import MixinFormat from '@/mixins/format'

export default {
  name: 'updatePayment',

  components: {
    SettlePaymentDailog,
    selectPayment,
  },
  mixins: [MixinOptions,MixinRules,MixinFormat],
  props:{
    form:{
      type:Object,
      default:()=>({})
    },
    isBack:{
      type:Boolean,
      default:true
    }
  },
  data(){
    return{
      approve:false,
      isPayment:false,
      formDataLock:0,
      formData:{
        payment:{}
      },
      subForm: {
        'set-payment':false,
      },
    }
  },
  computed: {
    orderNo(){
      return this.$route.query.order_no
    },
    isEndorsement()
    {
      return this.formData.policy_type ==203
    },
    isCancel(){
      return this.formData.policy_type ==204
    },
    productId(){
      return this.formData.product_id
    },
    isLock(){
      let {premium={}} = this.formData||{}
      return (premium.is_refund_order||premium.amount_received<=0)&&(this.isEndorsement||this.isCancel)
    },
    payment(){
      let {payment={}} = this.formData||{}
      return payment||{}
    }
  },
  created() {
    this.network().getOrderDetail()
  },
  methods:{
    ...mapActions('quotation', ['setQuoteInformationForm','setProductID']),
    handlerCancelConfirm(){
      let order_no = this.$route.query.order_no
      this.$router.push({
        path:'/quotation/create/policy',
        query:{
          order_no
        }
      })
    },

    handlerCancel(){
      let {order_status,policy_type} = this.formData
      // if(policy_type!=200) return

    },
    handlerApprove(){
      this.approve=true
      Object.keys(this.subForm).some(key => {
        this.subForm[key] = false
        this.$refs[key].submitForm(key)
      })
      if (Object.values(this.subForm).some(item => item === false)) {
        return
      }
      let {payment} = this.formData
      let {handle_name } = payment
      let name =handle_name
      this.$ConfirmBox({
        title: '',
        message: name+',are you confirmed that the payment is complete?'
      }).then(() => {
        this.isPayment?this.network().approvePayment(): this.handlerValidate()
      })
    },

    handlerValidate() {
      // 獲取表單組件數據
      let order_no = this.$route.query.order_no

      Object.keys(this.subForm).some(key => {
        this.subForm[key] = false
        this.$refs[key].submitForm(key)
      })
      if (Object.values(this.subForm).some(item => item === false)) {
        return
      }
      let {payment,policy_id} = this.formData
      let { type,last_name,first_name,handle_name } = payment

      if(type==2&&!this.approve)
      {
        this.$ConfirmBox({
          title: '',
          message: 'Approve Cheque later'
        }).then(() => {
          // console.log('this.isPayment',this.isPayment)
          if(this.isPayment)
          {
            this.$router.push({
              path:'/policy/info',
              query:{
                policy_id
              }
            })
          }else
            this.network().setOrderPayment()
        })
      }
      else if(type==1){
        let name = type==1?`${last_name} ${first_name}`:handle_name
        this.$ConfirmBox({
          title: '',
          message: 'SMS Payment will send to  '+name+',are you confirmed?'
        }).then(() => {
          this.network().setOrderPayment()
        })
      } else{
        this.network().setOrderPayment()

      }
    },
    getSubFormData(form, data) {
      this.subForm[form] = true
      this.formData = Object.assign(this.formData, data)
    },
    network() {
      return {
        getOrderDetail: async () => {
          let order_no = this.$route.query.order_no
          let { data } = await getOrderDetail({order_no:order_no})
          let {payment}  =data.data
          if(payment&&payment.type!=6)
            this.isPayment =true
          this.formData = data.data
          this.formDataLock = 1
        },
        setOrderPayment:async (params) => {
          let order_no = this.$route.query.order_no
          let {policy_id} = this.formData
          params = {...this.formData.payment,...{order_no:order_no}}
          let { data } = await setOrderPayment(params)
          let {type} = this.formData.payment
          this.isPayment = true

          if(this.approve)
          {
            this.network().approvePayment()
          }else{
            if(type==2)
              this.$router.push({
                path:'/policy/info',
                query:{
                  policy_id
                }
              })
            else
              this.$router.push({
                path:'/quotation/create/policy',
                query:{
                  order_no
                }
              })
          }
        },
        approvePayment:async ()=>{
          let order_no = this.$route.query.order_no
          let {policy_id} = this.formData
          this.approve = false
          let { data } = await approvePayment({order_no:order_no})
          this.$router.push({
            path:'/policy/info',
            query:{
              policy_id
            }
          })
        },
        setOrderConfirm:async ()=>{
          let order_no = this.$route.query.order_no
          let {policy_id} = this.formData
          let { data } = await setOrderConfirm({order_no:order_no})
          this.$router.push({
            path:'/policy/info',
            query:{
              policy_id
            }
          })
        },
        orderDownQuotation:async (params) => {
          let order_no = this.$route.query.order_no
          const { data } = await orderDownQuotation({order_no:order_no})
          this.network().getOrderDetail()
          this.$refs.page.scrollIntoView({behavior: 'smooth', block: 'end',})

          // this.quotationData = data
          // this.openFileDocument(data)
        },
      }
    }
  }
}
</script>

<style scoped lang="scss">
.page-container{
  .header-title{
    padding: 10px 0;
  }
  .paid-item{
    align-items: center;
    font-size: 16px;
    margin-bottom: 30px;
    span{
      color: #718096;
    }
    .pay{
      background: #E2E8F0;
      border-radius: 4px;
      color: #718096;
      padding: 5px 10px;
      margin-left: 10px;
      font-size: 16px;
    }
  }

  .header-title{
    font-size: 36px;
    color: #000;
    margin-bottom: 30px;
    margin-top: 10px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
  }
  ::v-deep .table{
    .th{
      background: #CBD5E0;
      box-shadow: 0px 1px 0px #CBD5E0;
      font-weight: bold;
      font-size: 14px;
    }

  }
  .margin-right{
    margin-right: 5px;
  }
}
</style>
